
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import ApiService from "@/core/services/ApiService";

export default {
  name: "RatesSale",
  props: {
    is_edit: {
      type: Boolean,
      default: false,
    },
    is_range: {
      type: Boolean,
      default: true,
    },
    level_id: {
      type: Number,
      default: undefined,
    },
    range: {
      type: Object,
    },
    offices: {
      type: Array,
    },
  },

  setup(props, { emit }) {
    const store = useStore();
    const disableUnitPrice = ref(true);
    const disableFixedPrice = ref(false);
    const levels = computed(() => store.getters.LevelTariff);
    const rates = computed(() => store.getters.Rates);
    const lines = ref([] as any);
    const inputLevel = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });
    const headerSimple = ref([
      { label: "itype", prop: "type", required: "1" },
      { label: "ifixedprice", prop: "fixed_price", required: "1" },
      { label: "ifactor", prop: "pay_unit", required: "1" },
      { label: "iissurcharge", prop: "is_surcharge", required: "0" },
    ]);
    const headerRange = ref([
      { label: "itype", prop: "type", required: "1" },
      { label: "iisextended", prop: "is_extended", required: "0" },
      { label: "imin", prop: "min", required: "1" },
      { label: "imax", prop: "max", required: "1" },
      { label: "iisfixed", prop: "is_fixed", required: "0" },
      { label: "ifixedprice", prop: "fixed_price", required: "0" },
      { label: "iunitprice", prop: "unit_price", required: "0" },
      { label: "iminprice", prop: "min_price_limit", required: "0" },
      { label: "imaxprice", prop: "max_price_limit", required: "0" },
      { label: "ifactor", prop: "pay_unit", required: "1" },
      { label: "iissurcharge", prop: "is_surcharge", required: "0" },
    ]);
    const headerPercent = ref([
      { label: "itype", prop: "type", required: "1" },
      { label: "iisfixed", prop: "is_fixed", required: "0" },
      { label: "ifixedprice", prop: "fixed_price", required: "0" },
      { label: "ifactor", prop: "pay_unit", required: "0" },
      { label: "ipercentage", prop: "percent", required: "0" },
      { label: "iminprice", prop: "min_price_limit", required: "0" },
      { label: "imaxprice", prop: "max_price_limit", required: "0" },
      { label: "iissurcharge", prop: "is_surcharge", required: "0" },
      {
        label: "itariffcriterialevel",
        prop: "billing_concept_ids",
        required: "0",
      },
    ]);
    const headers = ref(headerSimple.value);
    const headerTable = ref(headers.value);
    const types = ref([
      { value: "simple", label: "isimple" },
      { value: "percent", label: "ipercentage" },
      { value: "range", label: "irange" },
    ]);

    watch(
      () => props.is_range,
      (first) => {
        if (first) {
          updateElements("range");
          createElement();
        } else {
          lines.value = [];
          const item = {
            id: null,
            type: "simple",
            is_extended: "0",
            min: null,
            max: null,
            condition: "",
            revenue: null,
            revenue_percent: null,
            revenue_unit: null,
            is_fixed: "1",
            fixed_price: 1000,
            unit_price: 0,
            min_price_limit: null,
            max_price_limit: null,
            pay_unit: 1,
            is_surcharge: "1",
            tariff_criteria_id: null,
            percent: 0,
            billing_concept_ids: null,
          };
          lines.value.push(item);
          emit("addRate", item);
        }
      }
    );

    const onChangeExtended = (value) => {
      lines.value.forEach((x) => (x.is_extended = value));
    };

    const createElement = () => {
      const item = {
        id: null,
        type: "range",
        is_extended: lines.value.length ? lines.value[0].is_extended : "0",
        min: null,
        max: null,
        condition: "",
        revenue: null,
        revenue_percent: null,
        revenue_unit: null,
        is_fixed: "1",
        fixed_price: 1000,
        unit_price: 0,
        min_price_limit: null,
        max_price_limit: null,
        pay_unit: 1,
        is_surcharge: "1",
        tariff_criteria_id: props.range.criteria
          ? props.range.criteria.id
          : null,
        percent: 0,
        billing_concept_ids: null,
      };
      lines.value.push(item);
      emit("addRate", item);
    };

    const onClickRemove = (index) => {
      lines.value.splice(index, 1);
      store.commit("removeRate", index);
    };

    const checkFixedPrice = (item, index) => {
      if (lines.value[index].is_fixed === "0") {
        lines.value[index].fixed_price = "";
        lines.value[index].unit_price = 1;
        lines.value[index].min_price_limit = 1;
        lines.value[index].max_price_limit = 1;
        lines.value[index].percent =
          lines.value[index].type === "percent" ? 0.25 : 0;
      } else {
        lines.value[index].fixed_price = 1000;
        lines.value[index].unit_price = "";
        lines.value[index].min_price_limit = "";
        lines.value[index].max_price_limit = "";
        lines.value[index].percent = "";
      }
    };

    const checkPercent = (item, index) => {
      if (
        lines.value[index].percent === "" ||
        lines.value[index].percent === 0
      ) {
        lines.value[index].fixed_price = 1000;
        lines.value[index].min_price_limit = "";
        lines.value[index].max_price_limit = "";
        lines.value[index].percent = "";
      }
    };

    const updateElements = (type) => {
      //lines.value = [];
      //store.commit("resetRate");
      switch (type) {
        case "simple":
          headers.value = headerSimple.value;
          break;
        case "percent":
          headers.value = headerPercent.value;
          break;
        case "range":
          headers.value = headerRange.value;
          break;
      }
      headerTable.value = headers.value;
    };

    const getLevel = () => {
      store.commit("setLoading", false);
      ApiService.query("/api/tariff_level", {
        params: {
          per_page: 25,
          offices_id: JSON.stringify(props.offices),
          cost_type_id: "[1]",
        },
      }).then((response: any) => {
        inputLevel.value.list = response.data.data;
        inputLevel.value.options = response.data.data;
      });
    };

    const selectLevel = (query) => {
      store.commit("setLoading", false);
      ApiService.query("/api/tariff_level", {
        params: {
          per_page: 25,
          offices_id: JSON.stringify(props.offices),
          search: query,
          cost_type_id: "[1]",
        },
      }).then((response: any) => {
        inputLevel.value.list = response.data.data;
        inputLevel.value.options = response.data.data;
      });
    };

    onMounted(() => {
      getLevel();
      if (!props.is_edit) {
        if (props.is_range) {
          updateElements("range");
          createElement();
        } else {
          const item = {
            id: null,
            type: "simple",
            is_extended: "0",
            min: null,
            max: null,
            condition: "",
            revenue: null,
            revenue_percent: null,
            revenue_unit: null,
            is_fixed: "1",
            fixed_price: 1000,
            unit_price: 0,
            min_price_limit: null,
            max_price_limit: null,
            pay_unit: 1,
            is_surcharge: "1",
            tariff_criteria_id: null,
            percent: 0,
            billing_concept_ids: null,
          };
          lines.value.push(item);
          emit("addRate", item);
        }
      } else {
        rates.value.forEach((x) => {
          updateElements(x.type);
          lines.value.push(x);
          /*if (x.tariff_criteria_id === props.level_id) {
            lines.value.push(x);
          }*/
        });
      }
    });

    return {
      lines,
      headers,
      headerTable,
      types,
      levels,
      inputLevel,
      disableUnitPrice,
      disableFixedPrice,
      selectLevel,
      onChangeExtended,
      onClickRemove,
      createElement,
      updateElements,
      checkFixedPrice,
      checkPercent,
    };
  },
};
