
import {
  computed,
  defineComponent,
  onMounted,
  onBeforeMount,
  ref,
  watch,
} from "vue";
import { Form, Field, ErrorMessage, useField } from "vee-validate";
import UserOfficesSelect from "@/components/catalogs-select/UserOfficesSelect.vue";
import TariffLevelSelect from "@/components/catalogs-select/TariffLevelSelect.vue";
import ElementsSelect from "@/components/catalogs-select/ElementsSelect.vue";
import CurrencyTariffSelect from "@/components/catalogs-select/CurrencyTariffSelect.vue";
import TaxesSelect from "@/components/catalogs-select/TaxesSelect.vue";
import TaxesTypeSelect from "@/components/catalogs-select/TaxesTypeSelect.vue";
import RatesSale from "@/components/quotations/RatesSale.vue";
import ApiService from "@/core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { ElNotification } from "element-plus";
import { useStore } from "vuex";
import SPSelect from '@/components/catalogs-select/SPSelect.vue' 

export default defineComponent({
  name: "TariffSaleOficialForm",
  components: {
    Form,
    Field,
    ErrorMessage,
    TariffLevelSelect,
    ElementsSelect,
    CurrencyTariffSelect,
    TaxesSelect,
    TaxesTypeSelect,
    RatesSale,
    UserOfficesSelect,
    SPSelect,
  },

  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const paramsRoute = ref(route.params);
    const sync = ref([] as any);
    const taxesDetails = ref([]) as any;
    const taxDetail = ref("");
    const type_data = ref([]) as any;
    const mounted = ref(true);
    const submitButton = ref<HTMLElement | null>(null);
    const rates = computed(() => store.getters.Rates);
    const search = ref("");
    const cont = ref(0);
    const criterias = ref([]) as any;
    const officesList = ref([]) as any;
    const ranges = ref([]) as any;
    const loading = ref(false);
    const criteriasModel = ref([]) as any;
    const edit_rate = ref(false);
    const tax_id = ref(undefined);
    const form = ref({
      id: undefined,
      name: "",
      fusion: "",
      offices: [],
      billing_concept_id: undefined,
      validate_date_from: "",
      validate_date_to: "",
      prohibited_edit_booking: "1",
      prohibited_edit_contract: "1",
      values: {
        tariff_id: undefined,
        currency_id: undefined,
        taxes_detail_id: undefined,
        taxes_type_id: undefined as any,
        sign: "==",
        values: [] as any,
      },
      rates: [] as any,
      unit_pay: undefined as any,
    });
    const unitsPays = ref<{
      key: '',
      id: 0,
      name: ''
    }[]>([])

    watch(
      () => tax_id.value,
      (first) => {
        if (first === 6) {
          ApiService.query(`/api/taxes_detail/lists`, {
            params: {
              taxes: [first],
              per_page: 10,
            },
          }).then(({ data }) => {
            data.taxes_details.forEach((x) => {
              type_data.value.push({
                id: x.taxes_type_id,
                name: x.tax_type.name,
              });
            });
            form.value.values.taxes_type_id = 3;
            const item = data.taxes_details.filter(
              (x) => x.taxes_type_id === 3
            );
            taxDetail.value = item.length ? item[0].percentage : "";
          });
        }
      }
    );

    const isNameRequired = (value) => {
      if (!value) {
        return t("rname");
      }
      return true;
    };

    const isLevelRequired = (value) => {
      if (!value) {
        return t("rlevel");
      }
      return true;
    };

    const isTypeRequired = (value) => {
      if (!value) {
        return t("rtarifftype");
      }
      return true;
    };

    const isValiddDateRequired = (value) => {
      if (!value) {
        return t("rvalidfrom");
      }
      return true;
    };

    const isValiddDateToRequired = (value) => {
      if (!value) {
        return t("rvalidfrom");
      }
      return true;
    };

    const { errorMessage: errorValidDate, value: validate_date_from } =
      useField("validate_date_from", isValiddDateRequired);

    const { errorMessage: errorValidDateTo, value: validate_date_to } =
      useField("validate_date_to", isValiddDateToRequired);

    const customerCriteriaExist = () => {
      const index = sync.value.findIndex((x) => x.description === "cliente");
      return index !== -1;
    };

    const updateTaxDetails = (value) => {
      const item = taxesDetails.value.filter((x) => x.tax_type.id === value);
      form.value.values.taxes_detail_id = item[0].id;
      taxDetail.value = item.length ? item[0].percentage : "";
    };

    const addRate = (item) => {
      console.log("add rate");
      store.commit("addRate", item);
    };

    const updateCriterias = (items) => {
      console.log('Siempre debo de entrar, item', items)
      if (!mounted.value) {
        form.value.values.values = [] as any;
      }
      mounted.value = false;
      criterias.value = items;
      criterias.value = items.filter((x) => x.criteria.type !== "Range");
      const any_range = items.filter((x) => x.criteria.type === "Range");
      ranges.value = [];
      if (cont.value > 0 && (rates.value.length > 1 || any_range.length)) {
        store.commit("resetRate");
      }
      items.forEach((item) => {
        if (item.unit_paid_selectable === 1) {
          unitsPays.value.push({
            key: item.criteria.name,
            name: item.criteria.real_name,
            id: item.id
          })
        }
        if (item.criteria.type === "Range") {
          ranges.value.push(item);
          updateValues(item, 0, "");
        } else if (item.criteria.type === "Boolean") {
          const index = form.value.values.values.findIndex(
            (x) => x.criteria_id === item.criteria.id
          );
          if (index === -1) {
            form.value.values.values.push({
              criteria_id: item.criteria.id,
              value: "1",
            });
            criteriasModel.value.push("1");
          }
        }
      });
      if (unitsPays.value.length === 1 && form.value.unit_pay === undefined ) {
        form.value.unit_pay = unitsPays.value[0].id
      }
      cont.value++;
    };

    const updateValueCustomer = (value) => {
      const index = sync.value.findIndex((x) => x.description === "client");
      if (index !== -1) {
        form.value.values.values.push({
          criteria_id: sync.value[index].criteria_id,
          value: value,
        });
      }
    };

    const updateValues = (param1, param2, param3) => {
      const index = form.value.values.values.findIndex(
        (x) => x.criteria_id === param1.criteria.id
      );
      if (index === -1) {
        form.value.values.values.push({
          criteria_id: param1.criteria.id,
          value: param3 !== undefined ? param3 : criteriasModel.value[param2],
        });
      } else {
        form.value.values.values[index].criteria_id = param1.criteria.id;
        form.value.values.values[index].value =
          param3 !== undefined
            ? param3
            : criteriasModel.value[param2] === "0"
            ? "1"
            : "0";
      }
    };

    onBeforeMount(() => {
      store.commit("resetRate");
    });

    onMounted(() => {
      store.commit("setLoadingStatus", true);
      loading.value = true;
      setCurrentPageBreadcrumbs("tariffs_sale", [
        {
          route: "/sell_tariffs_list",
          label: "tariffs_sale",
        },
      ]);
      ApiService.get(`/api/synchronize_criterias`).then(({ data }) => {
        sync.value = data;
      });
      ApiService.query(`/api/taxes_detail/lists`, {
        params: {
          per_page: 10,
        },
      }).then(({ data }) => {
        taxesDetails.value = data.taxes_details;
      });
      if (Object.keys(paramsRoute.value).length > 0 && paramsRoute.value.id) {
        edit_rate.value = true;
        ApiService.get(`/api/tariff_oficial/${paramsRoute.value.id}`).then(
          ({ data }) => {
            form.value.id = data.id;
            form.value.name = data.name;
            form.value.fusion = data.fusion;
            form.value.offices = data.offices.map((x) => x.id);
            officesList.value = data.offices;
            form.value.billing_concept_id = data.billing_concept_id;
            form.value.validate_date_from = data.validate_date_from;
            validate_date_from.value = data.validate_date_from;
            form.value.validate_date_to = data.validate_date_to;
            validate_date_to.value = data.validate_date_to;
            form.value.prohibited_edit_booking =
              data.prohibited_edit_booking === 1 ? "1" : "0";
            form.value.prohibited_edit_contract =
              data.prohibited_edit_contract === 1 ? "1" : "0";
            form.value.unit_pay = data.unit_pay ? data.unit_pay : undefined;
            if (data.tariff_values) {
              form.value.values.currency_id = data.tariff_values.currency_id;
              store.commit("setCurrency", [data.tariff_values.currency]);
              tax_id.value = data.tariff_values.taxes_detail.tax_id;
              form.value.values.taxes_detail_id =
                data.tariff_values.taxes_detail_id;
              form.value.values.taxes_type_id =
                data.tariff_values.taxes_detail.taxes_type_id;
              taxDetail.value = data.tariff_values.taxes_detail.percentage;
              form.value.values.sign = data.tariff_values.sign;
              store.commit("cleanRates");
              data.tariff_values.rates.forEach((x) => {
                let item = { ...x };
                if (x.type == "percent") {
                  item.billing_concept_ids =
                    item.billing_concept_ids.split(",");
                  item.is_extended = x.is_extended === 1 ? "1" : "0";
                  item.is_fixed = x.fixed_price > 0 ? "1" : "0";
                  item.is_surcharge = x.is_surcharge === 1 ? "1" : "0";
                  store.commit("addRate", item);
                } else {
                  item.is_extended = x.is_extended === 1 ? "1" : "0";
                  item.is_fixed = x.fixed_price > 0 ? "1" : "0";
                  item.is_surcharge = x.is_surcharge === 1 ? "1" : "0";
                  store.commit("addRate", item);
                }
              });
            }
            ApiService.get(
              `api/tariff_level/${form.value.billing_concept_id}`
            ).then((response: any) => {
              response[1].criterias.forEach((y: any) => {
                if (y.unit_paid_selectable === 1) {
                  unitsPays.value.push({
                    key: y.criteria.name,
                    name: y.criteria.real_name,
                    id: y.id
                  })
                }
                if (y.criteria.type !== "Range") {
                  const temp = data.tariff_conditions.find(
                    (z) => z.criteria_id === y.tariff_criteria_id
                  );
                  criteriasModel.value.push(
                    y.criteria.type !== "Boolean"
                      ? temp === undefined
                        ? undefined
                        : temp.value
                      : temp === undefined
                      ? undefined
                      : temp.value.toString()
                  );
                  addValues({
                    criteria_id: y.tariff_criteria_id,
                    value: temp === undefined ? undefined : temp.value,
                  });
                }
              });
              if (unitsPays.value.length === 1 && form.value.unit_pay === undefined ) {
                form.value.unit_pay = unitsPays.value[0].id
              }
              store.commit("setLoadingStatus", false);
            });
          }
        );
      } else if (localStorage.getItem("duplicate_id")) {
        edit_rate.value = true;
        const duplicate_id = localStorage.getItem("duplicate_id");
        ApiService.get(`/api/tariff_oficial/${duplicate_id}`).then(
          ({ data }) => {
            form.value.name = data.name;
            form.value.fusion = data.fusion;
            form.value.offices = data.offices.map((x) => x.id);
            officesList.value = data.offices;
            form.value.billing_concept_id = data.billing_concept_id;
            form.value.validate_date_from = data.validate_date_from;
            validate_date_from.value = data.validate_date_from;
            form.value.validate_date_to = data.validate_date_to;
            validate_date_to.value = data.validate_date_to;
            form.value.prohibited_edit_booking =
              data.prohibited_edit_booking === 1 ? "1" : "0";
            form.value.prohibited_edit_contract =
              data.prohibited_edit_contract === 1 ? "1" : "0";
            form.value.unit_pay = data.unit_pay ? data.unit_pay : undefined;
            if (data.tariff_values) {
              form.value.values.currency_id = data.tariff_values.currency_id;
              store.commit("setCurrency", [data.tariff_values.currency]);
              form.value.values.sign = data.tariff_values.sign;
              tax_id.value = data.tariff_values.taxes_detail.tax_id;
              form.value.values.taxes_detail_id =
                data.tariff_values.taxes_detail_id;
              form.value.values.taxes_type_id =
                data.tariff_values.taxes_detail.taxes_type_id;
              taxDetail.value = data.tariff_values.taxes_detail.percentage;
              form.value.values.sign = data.tariff_values.sign;
              store.commit("cleanRates");
              data.tariff_values.rates.forEach((x) => {
                delete x.created_at;
                delete x.deleted_at;
                delete x.updated_at;
                delete x.entity_id;
                delete x.level_field_tariff_id;
                if (x.type == "percent") {
                  let item = { ...x };
                  item.billing_concept_ids =
                    item.billing_concept_ids.split(",");
                  item.id = null;
                  store.commit("addRate", item);
                } else {
                  x.id = null;
                  store.commit("addRate", x);
                }
              });
            }
            ApiService.get(
              `api/tariff_level/${form.value.billing_concept_id}`
            ).then((response: any) => {
              response[1].criterias.forEach((y: any) => {
                if (y.unit_paid_selectable === 1) {
                  unitsPays.value.push({
                    key: y.criteria.name,
                    name: y.criteria.real_name,
                    id: y.id
                  })
                }
                if (y.criteria.type !== "Range") {
                  const temp = data.tariff_conditions.find(
                    (z) => z.criteria_id === y.tariff_criteria_id
                  );
                  criteriasModel.value.push(
                    y.criteria.type !== "Boolean"
                      ? temp === undefined
                        ? undefined
                        : temp.value
                      : temp === undefined
                      ? undefined
                      : temp.value.toString()
                  );
                  addValues({
                    criteria_id: y.tariff_criteria_id,
                    value: temp === undefined ? undefined : temp.value,
                  });
                }
              });
              if (unitsPays.value.length === 1 && form.value.unit_pay === undefined ) {
                form.value.unit_pay = unitsPays.value[0].id
              }
              store.commit("setLoadingStatus", false);
            });
          }
        );
        localStorage.removeItem("duplicate_id");
      } else {
        store.commit("setLoadingStatus", false);
      }
      loading.value = false;
    });

    const addValues = (item: any) => {
      form.value.values.values.push(item);
    };

    const createNew = () => {
      window.location.assign("/sell_tariffs");
    };

    const createElement = async (resetForm) => {
      store.commit("setLoadingStatus", true);
      form.value.rates.forEach((x) => {
        if (x.type === "percent" && x.billing_concept_ids.length) {
          x.billing_concept_ids = x.billing_concept_ids.toString();
        }
      });
      ApiService.post(`/api/tariff_oficial`, form.value)
        .then(({ data }) => {
          submitButton.value?.removeAttribute("data-kt-indicator");
          router.push({
            name: "sellTariffsEdit",
            params: { id: data.id },
          });
          store.commit("setLoadingStatus", false);
        })
        .catch((response) => {
          if (response.status === 400) {
            ElNotification({
              title: "Error",
              message: response.data.data,
              type: "error",
            });
          }
          submitButton.value?.removeAttribute("data-kt-indicator");
          store.commit("setLoadingStatus", false);
        });
    };

    const updateElement = (resetForm) => {
      store.commit("setLoadingStatus", true);
      const paramsRoute = route.params;
      form.value.rates.forEach((x) => {
        if (x.type === "percent" && x.billing_concept_ids.length) {
          x.billing_concept_ids = x.billing_concept_ids.toString();
        }
      });
      ApiService.put(`/api/tariff_oficial/${paramsRoute.id}`, form.value)
        .then(({ data }) => {
          emit("success", data);
          submitButton.value?.removeAttribute("data-kt-indicator");
          store.commit("setLoadingStatus", false);
          router.push({
            name: "sellTariffs",
          });
        })
        .catch(() => {
          submitButton.value?.removeAttribute("data-kt-indicator");
          store.commit("setLoadingStatus", false);
        });
    };

    watch(
      () => validate_date_from.value,
      (first) => {
        if (validate_date_to.value && validate_date_to.value <= first) {
          validate_date_to.value = null;
        } else {
          form.value.validate_date_from = first;
        }
      }
    );

    watch(
      () => validate_date_to.value,
      (first) => {
        if (first && first <= validate_date_from.value) {
          ElNotification({
            title: "Error",
            message: "La fecha desde debe ser mayor que la fecha hasta",
            type: "error",
          });
          validate_date_to.value = null;
        } else {
          form.value.validate_date_to = first;
        }
      }
    );

    const onSubmit = (value, { resetForm }) => {
      if (
        !validate_date_from.value ||
        validate_date_from.value === undefined ||
        validate_date_from.value === ""
      ) {
        validate_date_from.value = "";
        return;
      }
      if (
        !validate_date_to.value ||
        validate_date_to.value === undefined ||
        validate_date_to.value === ""
      ) {
        validate_date_to.value = "";
        return;
      }
      if (submitButton.value) {
        submitButton.value.setAttribute("data-kt-indicator", "on");
        if (rates.value.length) {
          form.value.rates = rates.value;
        }
        form.value.values.values = form.value.values.values.filter(
          (x: any) => x.value !== undefined
        );
        // console.log("form.value", form.value);
        if (form.value.id) {
          updateElement(resetForm);
        } else {
          createElement(resetForm);
        }
      } else {
        ElNotification({
          title: "Error",
          message: t("rrequiredfields"),
          type: "error",
        });
        store.commit("setLoadingStatus", false);
      }
    };

    return {
      loading,
      submitButton,
      tax_id,
      form,
      search,
      sync,
      criterias,
      ranges,
      rates,
      criteriasModel,
      paramsRoute,
      taxesDetails,
      type_data,
      taxDetail,
      validate_date_from,
      errorValidDate,
      errorValidDateTo,
      validate_date_to,
      edit_rate,
      officesList,
      unitsPays,
      addRate,
      createNew,
      updateValues,
      updateCriterias,
      onSubmit,
      isNameRequired,
      isLevelRequired,
      isTypeRequired,
      customerCriteriaExist,
      updateTaxDetails,
      updateValueCustomer,
    };
  },
});
